/* eslint-disable max-len */
import React from 'react';

import { Disclosure } from '@headlessui/react';

import Seo from '../components/Seo';
import Header from '../partial/Header';
import Footer from '../partial/Footer';
import PlusIcon from '../icons/PlusIcon';
import MinusIcon from '../icons/MinusIcon';

const PrivacyPage = () => (
  <div className="font-Nunito">
    <Seo title="Privacy Policy" />
    <div className="pb-[70px] md:pb-[85px]">
      <Header />
    </div>
    <section className="bg-primary w-full">
      <div className="max-w-7xl w-[80%] py-[75px] mx-auto">
        <h2 className="md:text-3xl text-9md font-bold tracking-tight text-white sm:text-4xl">Privacy Policy</h2>
        <p className="md:mt-[4px] mt-[2px] text-white pr-0 text-16md md:text-2md font-normal">Updated 25th May 2022</p>
      </div>
    </section>
    <section>
      <div className="max-w-7xl w-[80%] py-[75px] mx-auto">
        <h2 className="md:text-[35px] text-9md font-bold tracking-tight sm:text-4xl">Waychit Privacy Policy</h2>
        <p className="mt-[10px] mb-[20px] text-text pr-0 text-16md md:text-2md font-normal w-full lg:max-w-[70%]">
          Lorem ipsum dolor sit amet, in qui adhuc numquam veritus. Cu quo erroribus omittantur. Sea id debitis placerat, illud ceteros iudicabit sed ut, cibo invidunt disputationi eum ex. Est malis deleniti no, ea per semper debitis reprimique. Melius tractatos mei ei, ad mel vide graeci nostro, viderer fabellas mel at. Nec te habemus adipisci, tollit putant repudiare at eum.

          Lorem ipsum dolor sit amet, in qui adhuc numquam veritus. Cu quo erroribus omittantur. Sea id debitis placerat, illud ceteros iudicabit sed ut, cibo invidunt disputationi eum ex. Est malis deleniti no, ea per semper debitis reprimique. Melius tractatos mei ei, ad mel vide graeci nostro, viderer fabellas mel at. Nec te habemus adipisci, tollit putant repudiare at eum.

        </p>
        <div className="w-full lg:max-w-[70%] mt-16 lgmt-24">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between px-4 py-6 text-left font-medium text-primary text-[18px] md:text-[25px] items-center border-t-[1px]">
                  <span>What is your refund policy?</span>
                  {open ? <MinusIcon /> : <PlusIcon />}
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  Lorem ipsum dolor sit amet, in qui adhuc numquam veritus. Cu quo erroribus omittantur. Sea id debitis placerat, illud ceteros iudicabit sed ut.

                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between px-4 py-6 text-left font-medium text-primary text-[18px] md:text-[25px] items-center border-t-[1px]">
                  <span>Do you offer technical support?</span>
                  {open ? <MinusIcon /> : <PlusIcon />}
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  Lorem ipsum dolor sit amet, in qui adhuc numquam veritus. Cu quo erroribus omittantur. Sea id debitis placerat, illud ceteros iudicabit sed ut, cibo invidunt disputationi eum ex. Est malis deleniti no, ea per semper debitis reprimique. Melius tractatos mei ei, ad mel vide graeci nostro, viderer fabellas mel at. Nec te habemus adipisci, tollit putant repudiare at eum.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);

export default PrivacyPage;
